import { bisphosphonateOptions, glucocorticoidOptions, fractureLocationOptions, fractureCauseOptions } from './constant';
import { Auth } from 'aws-amplify'; // Import AWS Amplify Auth

const API_URL = process.env.REACT_APP_API_URL || '';

export const apiRequest = async (endpoint, options = {}) => {
  const url = `${API_URL}${endpoint}`;
  
  // Retrieve the JWT token from Cognito
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${jwtToken}`, // Append JWT token to headers
    ...options.headers,
  };

  const response = await fetch(url, { ...options, headers });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response;
};

export const getFormFields = (viewOnly) => {
  const formFields = {
    patientInfo: [
        { label: 'Patient Name', name: 'name', type: 'text', readOnly: true, colSize: 'col-3', showOnHistoryPage: true },
        { label: 'MRN', name: 'mrn', type: 'text', readOnly: true, colSize: 'col-3', showOnHistoryPage: true },
        { label: 'Birth Date', name: 'birthDate', type: 'date', readOnly: true, colSize: 'col-3', showOnHistoryPage: true },
        { label: 'Gender', name: 'gender', type: 'radio', options: ['Male', 'Female', 'Other'], readOnly: true, colSize: 'col-3', showOnHistoryPage: true }
    ],
    examDetails: [
        { label: 'Technologist Name/Initials', name: 'technologistName', type: 'text', required: true, colSize: 'col-12', showOnHistoryPage: true },
        { label: 'Age', name: 'age', type: 'text', readOnly: true, colSize: 'col-4', showOnHistoryPage: true },
        { label: 'Current Exam Date', name: 'currentExamDate', type: 'date', readOnly: true, colSize: 'col-4' },
        {
        label: 'Current Exam Location',
        name: 'currentExamLocation',
        type: 'radio',
        options: ['C-Site', 'M-Site', 'Q-Site'],
        readOnly: true,
        colSize: 'col-4'
        },
        {
        label: 'Previous Exam',
        name: 'hasPreviousExam',
        type: 'radio',
        options: ['Yes', 'No'],
        required: true,
        readOnly: viewOnly,
        colSize: 'col-4'
        },
        { label: 'Previous Exam Date', 
        name: 'previousExamDate', 
        type: 'date', 
        readOnly: viewOnly, 
        required: true, 
        colSize: 'col-4', 
        condition: (patient) => patient.hasPreviousExam === 'Yes'  
        },
        {
        label: 'Previous Exam Location',
        name: 'previousExamLocation',
        type: 'radio',
        options: ['C-Site', 'M-Site', 'Q-Site', "Different Machine / Site"],
        required: true,
        readOnly: viewOnly,
        colSize: 'col-4',
        condition: (patient) => patient.hasPreviousExam === 'Yes'  
        },
        { label: 'Current Weight (kg)', name: 'weight', type: 'number', step: '0.1', readOnly: viewOnly, required: true, colSize: 'col-3' },
        { label: 'Previous Weight (kg)', name: 'previousWeight', type: 'number', step: '0.1', readOnly: viewOnly, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site', required: true   },
        { label: 'Weight Change (%)', name: 'weightChange', type: 'text', readOnly: true, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site'},
        { label: 'Is Weight Change > 10%', name: 'isWeightChangeGreaterThan10Percent', type: 'text', readOnly: true, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site'   },
        { label: 'Current Height (cm)', name: 'height', type: 'number', step: '0.1', readOnly: viewOnly, required: true, colSize: 'col-3' },
        { label: 'Previous Height (cm)', name: 'previousHeight', type: 'number', step: '0.1', readOnly: viewOnly, required: true, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site'   },
        { label: 'Height Change (cm)', name: 'heightChange', type: 'text', readOnly: true, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site'   },
        { label: 'Is Height Loss > 2 cm?', name: 'isHeightChangeGreaterThan2CM', type: 'text', readOnly: true, colSize: 'col-3', condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site'   }
    ],
    menopause: [
        {
        label: 'Patient menopausal?',
        name: 'menopausalStatus',
        type: 'radio',
        options: ['Yes', 'No'],
        required: true,
        condition: (patient) => patient.gender !== 'Male', // Only render if patient is not male
        readOnly: viewOnly,
        colSize: 'col-6',
        showOnHistoryPage: true
        },
        { label: 'Age at Menopause', 
        name: 'ageAtMenopause', 
        type: 'number', 
        readOnly: viewOnly, 
        condition: (patient) => patient.menopausalStatus === 'Yes', 
        showOnHistoryPage: true,
        colSize: 'col-6' }
    ],
    fractureHistory: [
        {
        label: 'Fractures after the age of 40?',
        patientLabel: 'Have you ever broken any bones?',
        name: 'fractureHistory',
        type: 'radio',
        options: ['Yes', 'No'],
        required: true,
        readOnly: viewOnly,
        showOnHistoryPage: true,
        condition: (patient) => patient.age >= 40, 
        colSize: 'col-12'
        },
        {
        label: 'Fracture Details',
        note: 'Fractures of the forearm, vertebra, proximal femur, and proximal humerus are generally be regarded as fragility fractures if they occur subsequent to a fall from standing or sitting heights. Generally, craniofacial fractures and fractures of the hands and feet are not considered fragility fractures. Other types of fractures have weaker relationships to osteoporosis, but may be regarded as fragility fractures if the history suggests that the fracture occurred with a degree of trauma that would not normally be expected to lead to a broken bone',
        name: 'fractureDetails',
        showOnHistoryPage: true,
        type: 'table',
        fields: [
            { label: 'Location', name: 'location', type: 'select', options: fractureLocationOptions },
            { label: 'Cause', name: 'cause', type: 'select', options: fractureCauseOptions },
            { label: 'Age Bone Broken', name: 'age', type: 'number' },
            { label: 'Note', name: 'note', type: 'text', maxLength: 30 }
        ],
        condition: (patient) => patient.fractureHistory === 'Yes',
        readOnly: viewOnly,
        colSize: 'col-12'
        }
    ],
    medications: [
        { label: 'Prednisone: 7.5 mg/day prednisone for at least 3 months within the last 12 months?', patientLabel: 'Have you taken steroid pills such as prednisone or cortisone for more than 3 months in the last 12 months?', note:'Glucocorticoid status is considered positive if prednisone (or other glucocorticoids in terms of prednisone equivalents) was in use at a dose equal to or greater than 7.5 mg per day for more than 3 cumulative months in the prior 12 months (meaning for more than 90 total days out of the preceding 365 days, not necessarily consecutive)."', name: 'glucocorticoidUsage', type: 'radio', options: ['Yes', 'No'], required: true, readOnly: viewOnly, colSize: 'col-12', showOnHistoryPage: true },
        { 
        label: 'Drug Name', 
        name: 'glucocorticoidName', 
        type: 'select', 
        options: glucocorticoidOptions, 
        condition: (patient) => patient.glucocorticoidUsage === 'Yes', 
        readOnly: viewOnly, 
        colSize: 'col-6',
        showOnHistoryPage: true
        },
        { label: 'Dosage (mg)', name: 'glucocorticoidDosage', type: 'number', condition: (patient) => patient.glucocorticoidUsage === 'Yes', readOnly: viewOnly, colSize: 'col-6', showOnHistoryPage: true },
        { label: 'Prednisone Equivalent Dosage (mg)', name: 'prednisoneDosage', type: 'number', condition: (patient) => patient.glucocorticoidUsage === 'Yes' && patient.glucocorticoidName !== 'Prednisone', readOnly: true, colSize: 'col-12', showOnHistoryPage: true },
        { label: 'Start Date (M-Y)', name: 'glucocorticoidStartDate', type: 'month', condition: (patient) => patient.glucocorticoidUsage === 'Yes', readOnly: viewOnly, colSize: 'col-6', showOnHistoryPage: true },
        { label: 'Duration (Months)', name: 'glucocorticoidDurationMonths', type: 'text', condition: (patient) => patient.glucocorticoidUsage === 'Yes', readOnly: true, colSize: 'col-6', showOnHistoryPage: true },
        { label: 'Glucocorticoid Status', name: 'glucocorticoidStatus', type: 'text', condition: (patient) => patient.glucocorticoidUsage === 'Yes', readOnly: true, colSize: 'col-12', showOnHistoryPage: true },
        { label: 'Current Bone Active Drug Therapy', patientLabel: 'Are you taking medication(s) for osteoporosis?', name: 'boneTherapyUsage', type: 'radio', options: ['Yes', 'No'], required: true, readOnly: viewOnly, colSize: 'col-12', showOnHistoryPage: true },
        { 
        label: 'Drug Name', 
        name: 'boneTherapyName', 
        type: 'select', 
        options: bisphosphonateOptions, 
        condition: (patient) => patient.boneTherapyUsage === 'Yes', 
        readOnly: viewOnly, 
        colSize: 'col-12',
        showOnHistoryPage: true
        },
        { 
        label: 'Specify Drug Name', 
        name: 'boneTherapyOtherName', 
        type: 'text', 
        condition: (patient) => patient.boneTherapyUsage === 'Yes' && patient.boneTherapyName === 'Other', 
        readOnly: viewOnly, 
        colSize: 'col-12' ,
        showOnHistoryPage: true
        },

        { 
        label: 'Start Date (Month/Year)', 
        name: 'boneTherapyStartDate', 
        type: 'month', 
        condition: (patient) => patient.boneTherapyUsage === 'Yes', 
        readOnly: viewOnly, 
        colSize: 'col-6' ,
        showOnHistoryPage: true
    },
    { 
        label: 'Duration (Months)', 
        name: 'boneTherapyDurationMonths', 
        type: 'text', 
        condition: (patient) => patient.boneTherapyUsage === 'Yes', 
        readOnly: true, 
        colSize: 'col-6' ,
        showOnHistoryPage: true
    }
    ],
    examItems: [
        { label: 'AP Spine Scanned', name: 'apSpineScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-12' },
        { label: 'Left Femur Scanned', name: 'leftFemurScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-6' },
        { label: 'Right Femur Scanned', name: 'rightFemurScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-6' },
        { label: 'Left Forearm Scanned', name: 'leftForearmScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-6' },
        { label: 'Right Forearm Scanned', name: 'rightForearmScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-6' },
        { label: 'Total Body Scanned', name: 'totalBodyScanned', type: 'radio', options: ['Yes', 'No'], required: false, readOnly: viewOnly, colSize: 'col-6' },
    ],
    boneDensity: [
        { label: 'Lowest T-score of Femoral Neck (Based on Female Database)', name: 'femaleFemoralNeckTScore', type: 'text', readOnly: viewOnly, required: true, colSize: 'col-12', condition: (patient) => patient.gender === 'Male' && patient.age >= 50 }
    ],
    precisionTesting: [
        { 
        label: 'AP Spine L1-L4 (cm²)', 
        name: 'currentAPSpine', 
        type: 'number', 
        step: '0.01', 
        required: true,
        readOnly: viewOnly, 
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.apSpineScanned === 'Yes', 
        colSize: 'col-12' 
        },
        { 
        label: 'AP Spine L1-L4 (cm²)', 
        name: 'currentAPSpine', 
        type: 'number', 
        step: '0.01', 
        required: true,
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousAPSpine', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentAPSpineChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isAPSpineChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes', 
        colSize: 'col-3' 
        },

        // Left Femur
        { 
        label: 'Left Femur Total (cm²)', 
        name: 'currentLeftFemur', 
        type: 'number', 
        step: '0.01', 
        required: true,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.leftFemurScanned === 'Yes', 
        readOnly: viewOnly, 
        colSize: 'col-12' 
        },
        { 
        label: 'Left Femur Total (cm²)', 
        name: 'currentLeftFemur', 
        type: 'number', 
        step: '0.01', 
        required: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes', 
        readOnly: viewOnly, 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousLeftFemur', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentLeftFemurChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isLeftFemurChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },

        // Right Femur
        { 
        label: 'Right Femur Total (cm²)', 
        name: 'currentRightFemur', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.rightFemurScanned === 'Yes', 
        colSize: 'col-12' 
        },
        { 
        label: 'Right Femur Total (cm²)', 
        name: 'currentRightFemur', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousRightFemur', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentRightFemurChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isRightFemurChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes', 
        colSize: 'col-3' 
        },

        // Left Forearm
        { 
        label: 'Left Forearm Total (cm²)', 
        name: 'currentLeftForearm', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.leftForearmScanned === 'Yes', 
        colSize: 'col-12' 
        },
        { 
        label: 'Left Forearm Total (cm²)', 
        name: 'currentLeftForearm', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousLeftForearm', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentLeftForearmChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isLeftForearmChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },

        // Right Forearm
        { 
        label: 'Right Forearm Total (cm²)', 
        name: 'currentRightForearm', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.rightForearmScanned === 'Yes', 
        colSize: 'col-12' 
        },
        { 
        label: 'Right Forearm Total (cm²)', 
        name: 'currentRightForearm', 
        type: 'number', 
        step: '0.01', 
        required: true,
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousRightForearm', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentRightForearmChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isRightForearmChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes', 
        colSize: 'col-3' 
        },
        // Total Body
        { 
        label: 'Total Body (cm²)', 
        name: 'currentTotalBody', 
        type: 'number', 
        step: '0.01', 
        required: true,
        readOnly: viewOnly, 
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.totalBodyScanned === 'Yes', 
        colSize: 'col-12' 
        },
        { 
        label: 'Total Body (cm²)', 
        name: 'currentTotalBody', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        required: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Previous (cm²)', 
        name: 'previousTotalBody', 
        type: 'number', 
        step: '0.01', 
        readOnly: viewOnly, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change (%)', 
        name: 'percentTotalBodyChange', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes', 
        colSize: 'col-3' 
        },
        { 
        label: 'Change > 2%', 
        name: 'isTotalBodyChangeGreaterThan2Percent', 
        type: 'text', 
        readOnly: true, 
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes', 
        colSize: 'col-3' 
        }
    ],
    scanMode: [
        {
        label: 'AP Spine Scan Mode',
        name: 'apSpineScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        required: true,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.apSpineScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'AP Spine Scan Mode',
        name: 'apSpineScanMode',
        type: 'radio',
        required: true,
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousApSpineScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'apSpineScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.apSpineScanned === 'Yes',
        colSize: 'col-4'
        },

        // Left Femur Scan Mode
        {
        label: 'Left Femur Scan Mode',
        name: 'leftFemurScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.leftFemurScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'Left Femur Scan Mode',
        name: 'leftFemurScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousLeftFemurScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'leftFemurScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftFemurScanned === 'Yes',
        colSize: 'col-4'
        },

        // Right Femur Scan Mode
        {
        label: 'Right Femur Scan Mode',
        name: 'rightFemurScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.rightFemurScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'Right Femur Scan Mode',
        name: 'rightFemurScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousRightFemurScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'rightFemurScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightFemurScanned === 'Yes',
        colSize: 'col-4'
        },

        // Left Forearm Scan Mode
        {
        label: 'Left Forearm Scan Mode',
        name: 'leftForearmScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.leftForearmScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'Left Forearm Scan Mode',
        name: 'leftForearmScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousLeftForearmScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'leftForearmScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.leftForearmScanned === 'Yes',
        colSize: 'col-4'
        },

        // Right Forearm Scan Mode
        {
        label: 'Right Forearm Scan Mode',
        name: 'rightForearmScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.rightForearmScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'Right Forearm Scan Mode',
        name: 'rightForearmScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousRightForearmScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'rightForearmScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.rightForearmScanned === 'Yes',
        colSize: 'col-4'
        },
        // Total Body Scan Mode
        {
        label: 'Total Body Scan Mode',
        name: 'totalBodyScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => (patient.hasPreviousExam === 'No' || (patient.hasPreviousExam === 'Yes' && patient.previousExamLocation === 'Different Machine / Site')) && patient.totalBodyScanned === 'Yes',
        colSize: 'col-12'
        },
        {
        label: 'Total Body Scan Mode',
        name: 'totalBodyScanMode',
        required: true,
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Previous Scan Mode',
        name: 'previousTotalBodyScanMode',
        type: 'radio',
        options: ['Standard', 'Thick', 'Thin'],
        readOnly: viewOnly,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Changed From Last',
        name: 'totalBodyScanModeChange',
        type: 'radio',
        options: ['Yes', 'No'],
        readOnly: true,
        condition: (patient) => patient.hasPreviousExam === 'Yes' && patient.previousExamLocation !== 'Different Machine / Site' && patient.totalBodyScanned === 'Yes',
        colSize: 'col-4'
        },
        {
        label: 'Block Height',
        name: 'blockHeight',
        type: 'radio',
        options: ['High', 'Medium', 'Low'],
        readOnly: viewOnly,
        colSize: 'col-12'
        },
    ],
    miscellaneous: [
        { label: 'OHIP Risk', name: 'ohipRisk', type: 'radio', options: ['High', 'Low', 'Baseline'], readOnly: viewOnly, required: true, colSize: 'col-12' },
        { label: 'Notes', name: 'notes', type: 'textarea', readOnly: viewOnly, colSize: 'col-12', showOnHistoryPage: true },
    ]
  };
  return formFields
}

export const calculatePercentageChange = (current, previous) => {
  if (!current || !previous) return null;
  const change = ((current - previous) / previous) * 100;
  return change.toFixed(2);
};

export const calculateAbsoluteChange = (current, previous) => {
  if (!current || !previous) return null;
  const change = current - previous;
  return change.toFixed(2);
};

export const calculateDurationMonths = (startDate, endDate) => {
  if (!startDate) return '';
  const start = new Date(startDate);
  const now = new Date(endDate);
  const yearsDiff = now.getFullYear() - start.getFullYear();
  const monthsDiff = now.getMonth() - start.getMonth();
  return yearsDiff * 12 + monthsDiff;
};

export const calculateChange = (current, previous, changeFunc, thresholdFunc, threshold) => {
  const change = changeFunc(current, previous);
  const isChangeSignificant = current && previous ? (thresholdFunc(change, threshold) ? 'Yes' : 'No') : '';
  return { change, isChangeSignificant };
};

export const calculateScanModeChange = (currentMode, previousMode) => {
  return currentMode && previousMode ? (currentMode !== previousMode ? 'Yes' : 'No') : '';
};

export const calculatePrednisoneDosage = (glucocorticoidName, glucocorticoidDosage) => {
    if (!glucocorticoidName | !glucocorticoidDosage){
      return
    }
    const drugEquivalents = {
      cortisone: 25,
      hydrocortisone: 20,
      prednisone: 5,
      prednisolone: 5,
      methylprednisolone: 4,
      triamcinolone: 4,
      betamethasone: 0.75,
      dexamethasone: 0.75,
      deflazacort: 6
    };
    const equivalentDose = drugEquivalents[glucocorticoidName.toLowerCase()] / 5 || 0;
    const convertedDose = glucocorticoidDosage / equivalentDose;
    return convertedDose.toFixed(2);
}

export const getRole = (user) => {
  let highestRole = ''
  let groups = user.signInUserSession.idToken.payload['cognito:groups'];
  if (groups && groups.includes('admin')) {
    return 'Admin'
  }
  groups = user.attributes['custom:groups'];
  if (groups) {
    groups = groups.split(',');
    groups.forEach(group => {
      if (group.includes('Administrators')) {
        highestRole = 'Super User';
      } else if (group.includes('Radiologist') && highestRole !== 'Super User') {
        highestRole = 'Radiologist';
      } else if (group.includes('Technologist') && highestRole === '') {
        highestRole = 'Technologist';
      }
    });
  }
  return highestRole
}