import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Select, MenuItem } from '@mui/material';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { apiRequest } from '../../util';
import Cookies from 'js-cookie';

const WorklistPage = () => {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [selectedLocation, setSelectedLocation] = useState(Cookies.get('selectedLocation') || 'All');

  useEffect(() => {
    Cookies.set('selectedLocation', selectedLocation);
  }, [selectedLocation]);

  const checkWorklist = async () => {
      try {
        setIsLoading(true)
        const response = await apiRequest('/api/patient/worklist');
        if (response.status === 200) {
            const data = await response.json();
            setPatients(data);
        } else {
            showSnackbar('Failed to fetch patients.', 'error');
            setPatients([]);
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching worklist:', error);
        setIsLoading(false)
      }
  };

  useEffect(() => {
    checkWorklist();
    const interval = setInterval(() => {
      checkWorklist();
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // Extract unique locations
  const uniqueLocations = ['All', ...new Set(patients.map(patient => patient.location))];

  // Filter patients based on selected location
  const filteredPatients = selectedLocation === 'All' ? patients : patients.filter(patient => patient.location === selectedLocation);

  return (
    <>
    <div className="App">
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
        <Select
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}
          variant="outlined"
          displayEmpty
          fullWidth
        >
          {uniqueLocations.map(location => (
            <MenuItem key={location} value={location}>{location}</MenuItem>
          ))}
        </Select>
      </div>
        {isLoading ? 
          <CircularProgress className="loading-spinner" /> :
        filteredPatients.length === 0 ? 
          <Typography variant="h6">No patients found, please use the search.</Typography> :
          <Grid container spacing={3} className="grid-container">
        {filteredPatients
          .sort((a, b) => {
            // Sort by location first, then by scheduled_start
            if (a.location < b.location) return -1;
            if (a.location > b.location) return 1;
            return new Date(a.scheduled_start) - new Date(b.scheduled_start);
          })
          .map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <a href={`/patient/${patient.id}`} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Card className="card">
                  <CardContent>
                    <Typography variant="h6">{patient.name.toUpperCase()}</Typography>
                    <Typography variant="body1">MRN: {patient.mrn}</Typography>
                    <Typography variant="body1">Identifiers: {patient.ids.join(', ')}</Typography>
                    <Typography variant="body1">Sex: {patient.gender}</Typography>
                    <Typography variant="body1">Birthdate: {new Date(patient.birth_date).toISOString().slice(0, 10)}</Typography>
                    <Typography variant="body1">Location: {patient.location}</Typography>
                    <Typography variant="body1">Scheduled Start: {patient.scheduled_start}</Typography>
                  </CardContent>
                </Card>
              </a>
          </Grid>
        ))
        }</Grid>
        }
    </div>
    </>
  );
};

export default WorklistPage;
