import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MedicalWorksheetForm from './MedicalWorksheetForm';
import { apiRequest } from '../../util';
import { useUserRole } from '../../Contexts/UserRoleContext';

const StudyPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(false);
  const { user } = useUserRole()

  
  const fetchStudyById = async (id) => {
    try {
      setIsLoading(true);
      const response = await apiRequest(`/api/worksheet/study/${id}`);
      const data = await response.json();
      if (response.ok) {
        setSelectedStudy({
          patientId: data.patientId || '',
          name: data.name || '',
          birthDate: data.birth_date || '',
          mrn: data.mrn || '',
          gender: mapGender(data.sex) || '',
          age: data.age || '',
          currentExamDate: data.date_time?.split('T')[0] || '',
          currentExamLocation: data.institution_name ? data.institution_name: '',
          previousExamLocation: data.previous_institution_name ? data.previous_institution_name: '',
          height: data.height || '',
          weight: data.weight || '',
          accession: data.accession || '',
          technologistName: user?.attributes?.name || '',
          ...data,
        });
        setIsLoading(false);
      } else {
        console.error('Error fetching study:', data.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching study:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyById(id);
    // eslint-disable-next-line
  }, [id]);
  
  const mapGender = (gender) => {
    switch (gender) {
    case 'female':
        return 'Female'
    case 'male':
        return 'Male'
    default:
        return 'Other'
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (!selectedStudy) return <div>Selected study not found.</div>;

  return (
    <>
      <MedicalWorksheetForm prefilledData={selectedStudy} />
    </>
  )
};

export default StudyPage;