import React, { useState, useEffect } from 'react';
import './MedicalWorksheetForm.css';
import { Modal, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { apiRequest, getFormFields, calculateAbsoluteChange, calculateDurationMonths, calculatePercentageChange, calculateChange, calculateScanModeChange, calculatePrednisoneDosage } from '../../util';  
import RenderField from '../../Components/RenderField';

const MedicalWorksheetForm = ({ prefilledData, viewOnly=false }) => {
  const [patient, setPatient] = useState({});
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const formFields = getFormFields(viewOnly)

  useEffect(() => {
    if (prefilledData) {
      setPatient((prev) => ({
        ...prev,
        ...prefilledData,
      }));
    }
  }, [prefilledData]);

  useEffect(() => {
    if (viewOnly) {
      return;
    }
    const weight = calculateChange(patient.weight, patient.previousWeight, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 10);
    const height = calculateChange(patient.height, patient.previousHeight, calculateAbsoluteChange, (change, threshold) => change < threshold, -2);
    const apSpine = calculateChange(patient.currentAPSpine, patient.previousAPSpine, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);
    const leftFemur = calculateChange(patient.currentLeftFemur, patient.previousLeftFemur, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);
    const rightFemur = calculateChange(patient.currentRightFemur, patient.previousRightFemur, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);
    const leftForearm = calculateChange(patient.currentLeftForearm, patient.previousLeftForearm, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);
    const rightForearm = calculateChange(patient.currentRightForearm, patient.previousRightForearm, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);
    const totalBody = calculateChange(patient.currentTotalBody, patient.previousTotalBody, calculatePercentageChange, (change, threshold) => Math.abs(change) > threshold, 2);

    const glucocorticoidDurationMonths = calculateDurationMonths(patient.glucocorticoidStartDate, patient.currentExamDate);
    const boneTherapyDurationMonths = calculateDurationMonths(patient.boneTherapyStartDate, patient.currentExamDate);

    const apSpineScanModeChange = calculateScanModeChange(patient.apSpineScanMode, patient.previousApSpineScanMode);
    const leftFemurScanModeChange = calculateScanModeChange(patient.leftFemurScanMode, patient.previousLeftFemurScanMode);
    const rightFemurScanModeChange = calculateScanModeChange(patient.rightFemurScanMode, patient.previousRightFemurScanMode);
    const leftForearmScanModeChange = calculateScanModeChange(patient.leftForearmScanMode, patient.previousLeftForearmScanMode);
    const rightForearmScanModeChange = calculateScanModeChange(patient.rightForearmScanMode, patient.previousRightForearmScanMode);
    const totalBodyScanModeChange = calculateScanModeChange(patient.totalBodyScanMode, patient.previousTotalBodyScanMode);

    setPatient((prev) => ({
      ...prev,
      weightChange: weight.change || '',
      isWeightChangeGreaterThan10Percent: weight.isChangeSignificant,
      heightChange: height.change || '',
      isHeightChangeGreaterThan2CM: height.isChangeSignificant,
      percentAPSpineChange: apSpine.change || '',
      isAPSpineChangeGreaterThan2Percent: apSpine.isChangeSignificant,
      percentLeftFemurChange: leftFemur.change || '',
      isLeftFemurChangeGreaterThan2Percent: leftFemur.isChangeSignificant,
      percentRightFemurChange: rightFemur.change || '',
      isRightFemurChangeGreaterThan2Percent: rightFemur.isChangeSignificant,
      percentLeftForearmChange: leftForearm.change || '',
      isLeftForearmChangeGreaterThan2Percent: leftForearm.isChangeSignificant,
      percentRightForearmChange: rightForearm.change || '',
      isRightForearmChangeGreaterThan2Percent: rightForearm.isChangeSignificant,
      percentTotalBodyChange: totalBody.change || '',
      isTotalBodyChangeGreaterThan2Percent: totalBody.isChangeSignificant,
      glucocorticoidDurationMonths: glucocorticoidDurationMonths || '',
      boneTherapyDurationMonths: boneTherapyDurationMonths || '',
      apSpineScanModeChange,
      leftFemurScanModeChange,
      rightFemurScanModeChange,
      leftForearmScanModeChange,
      rightForearmScanModeChange,
      totalBodyScanModeChange
    }));
    // eslint-disable-next-line
  }, [
    patient.weight,
    patient.previousWeight,
    patient.height,
    patient.previousHeight,
    patient.currentAPSpine,
    patient.previousAPSpine,
    patient.currentLeftFemur,
    patient.previousLeftFemur,
    patient.currentRightFemur,
    patient.previousRightFemur,
    patient.glucocorticoidStartDate,
    patient.boneTherapyStartDate,
    patient.currentExamDate,
    patient.apSpineScanMode,
    patient.previousApSpineScanMode,
    patient.leftFemurScanMode,
    patient.previousLeftFemurScanMode,
    patient.rightFemurScanMode,
    patient.previousRightFemurScanMode,
    patient.currentLeftForearm,
    patient.previousLeftForearm,
    patient.currentRightForearm,
    patient.previousRightForearm,
    patient.rightForearmScanMode,
    patient.previousRightForearmScanMode,
    patient.leftForearmScanMode,
    patient.previousLeftForearmScanMode,
    patient.currentTotalBody,
    patient.previousTotalBody,
    patient.totalBodyScanMode,
    patient.previousTotalBodyScanMode,
  ]);

  useEffect(() => {
    if (viewOnly){
      return;
    }
    const prednisoneDosage = calculatePrednisoneDosage(patient.glucocorticoidName, patient.glucocorticoidDosage);
    const glucocorticoidStatus = prednisoneDosage >= 7.5 && patient.glucocorticoidDurationMonths >= 3 ? "Positive" : "Negative";
    setPatient((prev) => ({
      ...prev,
      prednisoneDosage: prednisoneDosage || '',
      glucocorticoidStatus: glucocorticoidStatus || 'Negative',
    }));
  // eslint-disable-next-line
  }, [patient.glucocorticoidName, patient.glucocorticoidDosage, patient.glucocorticoidDurationMonths]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiRequest(`/api/worksheet/preview`, {
        method: 'POST',
        body: JSON.stringify(patient),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const pdfBlob = await response.blob();
      setPdfBuffer(URL.createObjectURL(pdfBlob)); // Convert blob to URL
      setModalIsOpen(true); // Open modal to display image
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const handleClose = () => {
    setModalIsOpen(false);
    setPdfBuffer(null);
  };

  const handleSave = async (e) => {
    if (viewOnly){
      return;
    }
    e.preventDefault();
    try {
      const response = await apiRequest(`/api/worksheet/`, {
        method: 'POST',
        body: JSON.stringify(patient),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      showSnackbar('Successfully saved.', 'success');
      setModalIsOpen(false);
      navigate(`/patient/${patient.patientId}`);
    } catch (error) {
      console.error('Error saving report:', error);
      showSnackbar('Failed to save. Please try again.', 'error');
    }
  };

  const handleSendToPacs = async (e) => {
    e.preventDefault();
    try {
      const response = await apiRequest(`/api/worksheet/${patient.worksheetId}/send-to-pacs`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      showSnackbar('Successfully sent to PACS.', 'success');
      setModalIsOpen(false);
      navigate(`/patient/${patient.patientId}`);
    } catch (error) {
      showSnackbar('Failed to send to PACS. Please try again.', 'error');
    }
  };

  const handlePrint = () => {
    if (pdfBuffer) {
      const printWindow = window.open(pdfBuffer);
      printWindow.print();
    }
  };

  // Conditional rendering for entire section
  const renderSection = (sectionKey) => {
    const fields = formFields[sectionKey].filter((field) => !field.condition || field.condition(patient));
    if (fields.length === 0) return null; // If no fields are applicable, don't render the section

    return (
      <section key={sectionKey} className="form-section">
        <h3>{sectionKey.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}</h3>
        {fields.map((field) => (
          <RenderField
            key={field.name}
            field={field}
            patient={patient}
            setPatient={setPatient}
            viewOnly={viewOnly}
          />
        ))}
      </section>
    );
  };


  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate(`/patient/${patient.patientId}`)}
        sx={{ mt: 2, mb: 2}}
      >
        Go Back
      </Button>
      <form onSubmit={handleSubmit} className="medical-worksheet-form">
        {Object.keys(formFields).map(renderSection)}
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
      {/* MUI Modal to display the generated image */}
      <Modal open={modalIsOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
            {pdfBuffer && (
              <div style={{ height: 450, overflow: 'auto' }}>
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                  <Viewer fileUrl={pdfBuffer} defaultScale={0.75}/>
                </Worker>
              </div>
            )}
          <Button variant="contained" onClick={handlePrint} sx={{ mt: 2 }}>
            Print
          </Button>
          {!viewOnly && (
          <Button variant="contained" onClick={handleSave} sx={{ mt: 2, ml: 2 }}>
            Confirm & Save
          </Button>
          )}
          {viewOnly && (
          <Button variant="contained" onClick={handleSendToPacs} sx={{ mt: 2 }}>
            Send To PACS
          </Button>
          )}
          <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MedicalWorksheetForm;