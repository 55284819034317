import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Home/Home';
import StudyPage from './Pages/Study/StudyPage';
import ViewWorksheetPage from './Pages/Study/ViewWorksheetPage';
import ExplanationReportPage from './Pages/Explanation/ExplanationReportPage';
import ReportPage from './Pages/Report/ReportPage'
import PatientDetailsPage from './Pages/Patient/PatientDetailsPage';
import HistoryPage from './Pages/History/HistoryPage';
import ViewHistoryPage from './Pages/History/ViewHistoryPage';
import CreatePatientForm from './Pages/Patient/CreatePatientPage';
import ConfigurationPage from './Pages/Configuration/ConfigurationPage'
import BasePage from './Components/BasePage';
import SignInPage from './Pages/SignIn/SignInPage'; 
import WorklistPage from './Pages/Worklist/WorklistPage';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { useUserRole } from './Contexts/UserRoleContext';

Amplify.configure(awsExports);

const App = () => {
  const { user } = useUserRole()

  return (
    <Router>
      <BasePage>
          {user ? ( // Check if the user is logged in
            <Routes>
              <Route path="/" element={<HomePage/>} />
              <Route path="/study/:id" element={<StudyPage/>} />
              <Route path="/explanation-report/:study_uid" element={<ExplanationReportPage/>} />
              <Route path="/report/:study_uid" element={<ReportPage/>} />
              <Route path="/patient/:id" element={<PatientDetailsPage/>} />
              <Route path="/patient/:id/add-history" element={<HistoryPage/>} />
              <Route path="/history/:id" element={<ViewHistoryPage/>} />
              <Route path="/worksheet/:id" element={<ViewWorksheetPage/>} />
              <Route path="/create-patient" element={<CreatePatientForm/>} />
              <Route path="/configuration" element={<ConfigurationPage/>} />
              <Route path="/worklist" element={<WorklistPage/>} />
            </Routes>
          ) : (
            <SignInPage /> // Render SignInPage if the user is not logged in
          )}
      </BasePage>
    </Router>
  );
};

export default App;