import { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styles from './NavBar.style'; // Assuming styles is saved in a separate file
import { useNavigate } from 'react-router-dom';
import { useUserRole } from '../Contexts/UserRoleContext';

const pages = ['Home', 'Refresh'];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();
  const Logo = process.env.PUBLIC_URL + '/c-encode-white.png';
  const { user, signOut, role} = useUserRole();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavClick = (page) => {
    if (page === 'Home') {
      navigate('/'); // Navigate to the home page
    } else if (page === 'Refresh') {
      window.location.reload(true);
    } else if (page === 'Configuration') {
      navigate('/configuration');
    } else if (page === 'Worklist') {
      navigate('/worklist');
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar sx={styles.appBar}>
      <Container maxWidth="xl">
        <Toolbar sx={styles.toolbar}>
          <Box component="img" src={Logo} alt="logo" sx={styles.logo} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={styles.logoTextLarge}
          >
            DXA Encode
          </Typography>
          <Box sx={styles.menuButtonBox}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={styles.mobileMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleNavClick(page)}>
                  <Typography sx={styles.menuItemText}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={styles.desktopMenuBox}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleNavClick(page)}
                sx={styles.menuButton}
              >
                {page}
              </Button>
            ))}
            {(role === 'Super User' || role === 'Admin' || role === 'Technologist') && (
              <Button
                onClick={() => handleNavClick('Worklist')}
                sx={styles.menuButton}
              >
                Worklist
              </Button>
            )}
            {(role === 'Super User' || role === 'Admin') && (
              <Button
                onClick={() => handleNavClick('Configuration')}
                sx={styles.menuButton}
              >
                Configuration
              </Button>
            )}
            {user && 
            <Button
              onClick={() => signOut()}
              sx={styles.menuButton}
            >
              Logout
            </Button>
            }
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={styles.logoTextSmall}
          >
            DXA Encode
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;